<template>
  <div class="mr-4 md:mr-0">
    <trac-loading v-if="isLoading" />
    <div class="wrapper" v-if="stores">
      <trac-back-button>terminals</trac-back-button>
      <div class="flex justify-between items-center">
        <h2>New device</h2>
        <trac-button
          :disabled="
            terminal.assignment.store_id === '' ||
            terminal.assignment.terminal_name === '' ||
            terminal.assignment.serial_no === ''
          "
          @button-clicked="EditTerminal"
          >{{ updateFlag ? 'Save' : 'Save' }}</trac-button
        >
      </div>
      <div class="md:w-8/12">
        <div class="mt-12 border-2 border-gray-100 p-12 bg-white md:ml-8">
          <h3>Device Info</h3>
          <trac-input
            v-model.trim="terminal.assignment.terminal_name"
            type="text"
            placeholder="Device Name"
            class="mt-6"
          />
          <trac-input
            v-model.trim="terminal.assignment.serial_no"
            type="text"
            placeholder="Serial Number"
            class="mt-6"
          />
        </div>
        <div class="my-12 border-2 border-gray-100 p-12 bg-white md:ml-8">
          <h3>Assignment</h3>
          <trac-dropdown-exteneded
            @optionSelected="selectOption($event)"
            placement="left"
            :options="stores"
            :neededProperty="'name'"
            class="my-6"
            :selector="
              updateFlag ? terminal.assignment.store_name : 'Select Store'
            "
          >
          </trac-dropdown-exteneded>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      isLoading: false,
      updateFlag: false,
      stores: null,
      selectedStore: null,
      terminal: {
        serial_no: "",
        terminal_provider_name: "",
        terminal_provider_id: "",
        assignment: {
          business_id: GET_USER_BUSINESS_ID(),
          date_assigned: "",
          serial_no: "",
          store_id: "",
          store_name: "",
          terminal_id: "",
          terminal_name: "",
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchTerminalInfo();
    await this.fetchAllStores();
    this.isLoading = false;
  },
  computed: {
    // terminal() {
    //   return this.$store.getters["GET_SINGLE_TERMINAL"];
    // },
  },
  methods: {
    async fetchTerminalInfo() {
      this.isLoading = true;
      let { terminal, serial } = this.$route.params;

      let k = {
        terminalProviderId: "",
        serialNo: "",
        businessID: "",
      };

      k.terminalProviderId = terminal;
      k.serialNo = serial;
      k.businessID = GET_USER_BUSINESS_ID();
      if (k.terminalProviderId && k.serialNo) {
        await this.$store.dispatch("FETCH_TERMINAL_BY_SERIALNUMBER", k);
        const res = this.$store.getters["GET_SINGLE_TERMINAL"];
        if (res.status) {
          this.updateFlag = true;
          this.terminal = res.data.items;
        }
      } else {
        await this.$store.dispatch("FETCH_ALL_TERMINAL_NAMES");
      }
      this.isLoading = false;
    },
    selectOption(option) {
      this.selectedStore = option;
      this.terminal.assignment.store_id = this.selectedStore._id;
      this.terminal.assignment.store_name = this.selectedStore.name;
    },
    async fetchAllStores() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
      this.isLoading = false;
    },
    async EditTerminal() {
      this.isLoading = true;

      const payload = {
        _id: this.terminal._id,
        updated_at: this.terminal.updated_at,
        is_assigned: this.terminal.is_assigned,
        created_at: this.terminal.created_at,
        store_id: this.terminal.assignment.store_id,
        store_name: this.terminal.assignment.store_name,
        terminal_name: this.terminal.assignment.terminal_name,
        business_id: GET_USER_BUSINESS_ID(),
        serial_no: this.terminal.assignment.serial_no,
        terminal_provider_name: this.terminal.terminal_provider_name,
        terminal_provider_id: this.terminal.terminal_provider_id,
      };

      const res = await this.$store.dispatch(
        "ADD_TERMINAL_TO_DB",
        payload
      );

      if (res.success) {
        eventBus.$emit("trac-alert", { message: res.message });
        this.$router.back();
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
